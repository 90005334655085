









































































import { useCurrency } from '@/composition/currency'
import {
  PropertyCategoryId,
  PropertyViewModel,
} from '@/services/modules/property'
import { getPropertyRouteTo } from '@/utils/property'
import { Location } from 'vue-router'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CompareListingsTablePropertyHeader',

  props: {
    estateProperty: {
      type: Object as PropType<PropertyViewModel>,
      required: true,
    },

    removeRouteTo: {
      type: Object as PropType<Location>,
      required: true,
    },
  },

  setup(props) {
    const propertyImage = computed(
      () => props.estateProperty.propertyImageList[0]
    )

    const propertyPrice = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const propertyPrice2 = computed(
      () =>
        useCurrency({
          value: props.estateProperty.price2,
          currency: props.estateProperty.currencyViewModel,
        }).value
    )

    const isRental = computed(
      () =>
        props.estateProperty.categoryViewModel.categoryId ===
        PropertyCategoryId.Rentals
    )

    const propertyRouteTo = computed(() =>
      getPropertyRouteTo(props.estateProperty)
    )

    return {
      propertyImage,
      propertyPrice,
      propertyPrice2,
      isRental,
      propertyRouteTo,
    }
  },
})
