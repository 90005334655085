
































































































import SiteSection from '@/components/SiteSection.vue'
import CompareListingsTable from '@/components/CompareListingsTable.vue'
import CompareListingsShareModal from '@/components/CompareListingsShareModal.vue'
import { compareListingStore } from '@/composition/compareListingStore'
import { useProperties } from '@/composition/properties'
import { useGroups } from '@/composition/groups'
import { currentRoute } from '@/router'
import { IntIdTableType } from '@/services/utils'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'CompareListings',

  components: {
    SiteSection,
    CompareListingsTable,
    CompareListingsShareModal,
  },

  setup() {
    const isShareModalOpen = ref(false)

    const propertyKeyList = computed(() => {
      const list: IntIdTableType[] = []

      if (currentRoute.value && currentRoute.value.query.key) {
        const routeList = Array.isArray(currentRoute.value.query.key)
          ? currentRoute.value.query.key
          : [currentRoute.value.query.key]
        for (const item of routeList) {
          if (!item) continue
          list.push({ id: Number.parseInt(item) })
        }
      }
      return list
    })

    const { data: propertyList } = useProperties(
      computed(() => ({
        pageNumber: 1,
        rowsPerPage: propertyKeyList.value.length,
        propertyKeyList: propertyKeyList.value,
        isDeleted: false,
      }))
    )

    // Make sure the compare listings list matches the route query
    watch(
      () => propertyList.value,
      (newValue) => {
        compareListingStore.removeAll()
        compareListingStore.addAll(newValue)
      }
    )

    const { data: propertyGroupList } = useGroups({
      isCompareListing: true,
    })

    function share() {
      isShareModalOpen.value = true
    }

    function print() {
      window.print()
    }

    return {
      isShareModalOpen,
      propertyKeyList,
      propertyList,
      propertyGroupList,
      share,
      print,
    }
  },
})
