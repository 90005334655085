































































































import CompareListingsTablePropertyHeader from '@/components/CompareListingsTablePropertyHeader.vue'
import { useCurrency } from '@/composition/currency'
import {
  PropertyDetailViewModel,
  PropertyGroupViewModel,
  PropertyViewModel,
} from '@/services/modules/property'
import i18n from '@/setup/i18n'
import { getCompareListingsRouteTo, getPropertyRouteTo } from '@/utils/property'
import { computed, defineComponent, PropType } from '@vue/composition-api'

interface CompareListingTableConfig {
  id: string
  title: string
  rows: string[][]
}

export default defineComponent({
  name: 'CompareListingsTable',

  components: {
    CompareListingsTablePropertyHeader,
  },

  props: {
    propertyGroupList: {
      type: Array as PropType<PropertyGroupViewModel[]>,
      required: true,
    },

    propertyList: {
      type: Array as PropType<PropertyViewModel[]>,
      required: true,
    },
  },

  setup(props) {
    const propertyListDetailList = computed<PropertyDetailViewModel[][]>(() => {
      const detailList = []

      for (const property of props.propertyList) {
        const propertyDetailList = []
        for (const group of property.propertyGroupList) {
          for (const detail of group.detailList) {
            propertyDetailList.push(detail)
          }
        }

        detailList.push(propertyDetailList)
      }

      return detailList
    })

    const tables = computed<CompareListingTableConfig[]>(() => {
      const result: CompareListingTableConfig[] = [
        {
          id: 'location',
          title: '',
          rows: [
            [
              i18n.t('propertyId') as string,
              ...props.propertyList.map((p) => p.mlsPropertyId),
            ],
            [
              i18n.t('city') as string,
              ...props.propertyList.map((p) => p.cityViewModel.name),
            ],
            [
              i18n.t('area') as string,
              ...props.propertyList.map((p) => p.areaViewModel.name),
            ],
            [
              i18n.t('zipCode') as string,
              ...props.propertyList.map((p) => p.addressViewModel.postalCode),
            ],
            [
              i18n.t('bedrooms') as string,
              ...props.propertyList.map((p) => p.bedroom.toString()),
            ],
            [
              i18n.t('bathrooms') as string,
              ...props.propertyList.map((p) => p.bathroom.toString()),
            ],
            [
              i18n.t('cleaningFee') as string,
              ...props.propertyList.map((p) =>
                p.cleaningFee
                  ? useCurrency({
                      value: p.cleaningFee,
                      currency: p.currencyViewModel,
                    }).value
                  : ''
              ),
            ],
          ],
        },
      ]

      for (const group of props.propertyGroupList) {
        const rows: string[][] = []

        for (const detail of group.detailList) {
          const row: string[] = [detail.propertyDetailTitle]
          for (const propertyDetailList of propertyListDetailList.value) {
            const propertyDetail = propertyDetailList.find(
              (pd) => pd.detailId === detail.detailId
            )
            let value = ''
            if (!propertyDetail) {
              value = 'false'
            } else if (propertyDetail.value === '') {
              value = 'true'
            } else if (propertyDetail.value) {
              value = propertyDetail.value
            }
            row.push(value)
          }
          rows.push(row)
        }

        result.push({
          id: group.name,
          title: group.title,
          rows: rows,
        })
      }

      return result
    })

    function getPropertyListWithoutCurrent(property: PropertyViewModel) {
      return props.propertyList.filter(
        (p) => p.propertyId !== property.propertyId
      )
    }

    return {
      tables,
      getPropertyRouteTo,
      getCompareListingsRouteTo,
      getPropertyListWithoutCurrent,
    }
  },
})
