var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"flex flex-col space-y-10 overflow-hidden"},[_c('BaseBox',{staticClass:"grid overflow-x-auto"},_vm._l((_vm.tables),function(table,tableIndex){return _c('BaseBox',{key:table.id,staticClass:"flex flex-col"},[_c('BaseBox',{staticClass:"py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"},[_c('BaseBox',[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[(tableIndex === 0 || table.title)?_c('thead',{staticClass:"table-row-group"},[_c('tr',{staticClass:"flex"},[(tableIndex === 0)?[_c('th',{staticClass:"flex-1 p-6",attrs:{"scope":"col"}}),_vm._l((_vm.propertyList),function(property){return _c('th',{key:property.propertyId,staticClass:"flex-1 px-6 pb-14",attrs:{"scope":"col"}},[_c('CompareListingsTablePropertyHeader',{attrs:{"estate-property":property,"remove-route-to":_vm.getCompareListingsRouteTo(
                          _vm.getPropertyListWithoutCurrent(property)
                        )}})],1)})]:(table.title)?[_c('th',{staticClass:"\n                      px-6\n                      py-3\n                      text-left\n                      font-medium\n                      text-gray-500\n                      uppercase\n                      tracking-wider\n                    ",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(table.title)+" ")])]:_vm._e()],2)]):_vm._e(),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200"},_vm._l((table.rows),function(row,rowIndex){return _c('tr',{key:rowIndex,staticClass:"flex"},_vm._l((row),function(column,columnIndex){return _c('td',{key:columnIndex,class:[
                    'px-6 py-4 whitespace-nowrap flex-1 truncate',
                    columnIndex === 0
                      ? 'text-lg font-semibold'
                      : 'text-center' ]},[(column === 'true' || column === 'false')?_c('BaseText',{class:[
                      column === 'true' ? 'text-positive' : 'text-critical' ]},[_c('BaseIcon',{attrs:{"name":column === 'true' ? 'check' : 'close-small',"source":"custom"}})],1):[_vm._v(" "+_vm._s(column)+" ")]],2)}),0)}),0)])])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }