










import appConfig from '@/app.config.json'
import router from '@/router'
import ShareModal from '@/components/ShareModal.vue'
import i18n from '@/setup/i18n'
import { getCompareListingsRouteTo } from '@/utils/property'
import { computed, defineComponent } from '@vue/composition-api'
import { compareListingStore } from '@/composition/compareListingStore'

export default defineComponent({
  name: 'CompareListingsShareModal',

  components: {
    ShareModal,
  },

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    function onUpdate(newValue: boolean) {
      context.emit('update', newValue)
    }

    const title = computed(() => i18n.t('compareListingsShareTitle') as string)

    const shareText = computed(
      () =>
        i18n.t('compareListingsShareText', { title: appConfig.title }) as string
    )

    const href = computed(
      () =>
        `${window.location.origin}${
          router.resolve(
            getCompareListingsRouteTo(compareListingStore.list.value)
          ).href
        }`
    )

    return {
      onUpdate,
      title,
      shareText,
      href,
    }
  },
})
